import React, { useState, useEffect } from 'react';
import GlobalStateContext from './GlobalStateContext';

const GlobalStateProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [options, setOptions] = useState({
	  currency: null
  });
  const [loading, setLoading] = useState(true);

  const setOption = (key, value) => {
	  setOptions({...options, [key]: value})
  }

  useEffect(() => {
	const fetchData = async () => {
	  try {
		/* try and fetch data from all init sources */
		
		let [
			determine_currency,
			list_products
		] = await Promise.all([
			(await fetch('https://purchase.hitmixbingo.com/determine_currency.php')).json(),
			(await fetch('https://purchase.hitmixbingo.com/list_products.php')).json(),
		]);
		
		/* sanitize list products */
		let currency_options = determine_currency.data.currency_options;
		list_products = await sanitizeListProducts(list_products, currency_options);
		
		setData({
			determine_currency,
			list_products
		});
		
		/* set default currency*/
		if(!options.currency) setOption('currency', determine_currency.data.currency_code)
		
		setLoading(false);
	  } catch (error) {
		console.error("Failed to fetch data:", error);
		setLoading(false);
	  }
	};

	fetchData();
  }, []);

  return (
	<GlobalStateContext.Provider value={{ data, loading, options, setOption }}>
	  {children}
	</GlobalStateContext.Provider>
  );
};

const sanitizeListProducts = (list_products, currency_codes) => {
	let oldData = list_products.data;
	let newData = {};
	currency_codes.forEach(currencyCode => {
		newData[currencyCode] = [];
	})
	
	const EXTRA_INFO = {
		'7 Days Use Only': {
			description: 'Purchase a license key which works for 7 days only. Ideal for single events.',
			image: 'https://placehold.co/400x240'
		},
		'Monthly Subscription': {
			description: "Start a monthly subscription to Hitmix Bingo.",
			image: 'https://placehold.co/400x240'
		},
		'Quarterly Subscription': {
			description: "Start a quarterly (every three months) subscription to Hitmix Bingo.",
			image: 'https://placehold.co/400x240'
		},
		'Yearly Subscription': {
			description: "Start an annual subscription to Hitmix Bingo.",
			image: 'https://placehold.co/400x240'
		},
		'Lifetime (1 Key)': {
			description: "Purchase a perpetual license for Hitmix Bingo!",
			image: 'https://placehold.co/400x240'
		},
		'Lifetime (2 Keys)': {
			description: "Purchase two perpetual licenses for Hitmix Bingo!",
			image: 'https://placehold.co/400x240'			
		}
	}
	
	oldData.forEach(item => {
		let product = item.product;
		if(currency_codes.includes(product.currency_code)){
			let newTitle = product.name.split(' - ')[1].split(product.currency_code)[0].trim().split('Bingo')[1].trim()
			product.shortName = newTitle;
			
			/* map extra product data by shortName */
			if(Object.keys(EXTRA_INFO).includes(product.shortName)){
				let extra_info = EXTRA_INFO[product.shortName];
				product = {...product, ...extra_info}
			}
			
			newData[product.currency_code].push(product);
		}
	})
	
	return { data: newData };
}

export default GlobalStateProvider;
