import React, { useContext, useState, useLayoutEffect, useRef } from 'react';
import Step from './Step';
import CurrencyChooser from './CurrencyChooser';
import GlobalStateContext from '../contexts/GlobalStateContext';
import ProductList from './ProductList';
import DetailsForm from './DetailsForm';
import HostDirectoryStep from './HostDirectoryStep';
import ConfirmOrderStep from './ConfirmOrderStep';

const PurchaseForm = () => {
	
	const { data, setOption } = useContext(GlobalStateContext);
	const { determine_currency } = data;

	const [showStep, setShowStep] = useState(1);
	const [userWantsPublish, setUserWantsPublish] = useState(null);
	const [product, setProduct] = useState(null);

	const stepRefs = useRef([useRef(null), useRef(null), useRef(null), useRef(null)]);
	
	useLayoutEffect(() => {
	    if (showStep !== 1 && stepRefs.current[showStep - 1].current) {
	        stepRefs.current[showStep - 1].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
	    }
	}, [showStep]);
	
	const [radioValue, setRadioValue] = useState(null);
	const handleRadioChange = (e) => {
	    setRadioValue(e.target.value);
	    setUserWantsPublish(e.target.value === "yes");
	    setOption('publish', e.currentTarget.value === "yes");
	    setShowStep(3);
	};
	
	const resetRadioValue = () => {
	    setRadioValue(null);
	};
	
	return (
	    <div className="main__form">
	        <h1>Purchase Hitmix Bingo</h1>
	        <CurrencyChooser
	            defaultValue={determine_currency.data.currency_code}
	            options={determine_currency.data.currency_options}
	            onChange={() => {
	                setProduct(null);
	                setShowStep(1);
	            }}
	        />
			
	        <Step
	            ref={stepRefs.current[0]}
	            showStep={showStep}
	            setShowStep={setShowStep}
	            number={1}
	            title={"What kind of license would you like to purchase?"}
	            stepContent={
	                <ProductList onChange={product => {
	                    setProduct(product);
	                    setOption('selected-product', product);
	                    console.log({ product });
	                    resetRadioValue();
	                    setShowStep(2);
	                }} />
	            }
	        />
			
	        <Step
	            ref={stepRefs.current[1]}
	            showStep={showStep}
	            setShowStep={setShowStep}
	            number={2}
	            title={"Would you like to be published in the online host directory?"}
	            stepContent={
	                <HostDirectoryStep
	                    radioValue={radioValue}
	                    onRadioChange={handleRadioChange}
	                />
	            }
	        />
			
	        <Step
	            ref={stepRefs.current[2]}
	            showStep={showStep}
	            setShowStep={setShowStep}
	            number={3}
	            title={"Please enter your details."}
	            description={"* fields are required."}
	            stepContent={
	                <DetailsForm showHostDirectoryFields={userWantsPublish} setShowStep={setShowStep} />
	            }
	        />
			
	        <Step
	            ref={stepRefs.current[3]}
	            showStep={showStep}
	            setShowStep={setShowStep}
	            number={4}
	            title={"Please confirm your order:"}
	            stepContent={
	                <ConfirmOrderStep />
	            }
	        />
	    </div>
	);
}

export default PurchaseForm;
