import React, { forwardRef } from 'react';

const Step = forwardRef(({ number = 0, title = "Step Name", stepContent, description, showStep, setShowStep }, ref) => {
	if (showStep < number) return null;
	return (
		<div className="step" ref={ref}>
			<div className="step__header">
				<div className="step__number">{number}</div>
				<div>
					<h2>{title}</h2>
					{description ? <h3>{description}</h3> : null}
				</div>
			</div>
			<div className="step__content">
				{stepContent}
			</div>
		</div>
	);
});

export default Step;
