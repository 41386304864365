import { z } from 'zod';

const DetailsFormSchema = z.object({
  'full-name': z.string().nonempty({ message: 'Full name is required' }),
  'company-name': z.string().nonempty({ message: 'Company name is required' }),
  'email': z.string().email({ message: 'Invalid email' }).nonempty({ message: 'Email is required' }),
  'contact-telephone': z.string().nonempty({ message: 'Contact telephone is required' }),
'website-url': z.string()
	.refine(data => /^$|(http(s)?:\/\/)?[^\s(["<,>]*\.[^\s[",><]+$/i.test(data), {
	  message: 'Invalid URL. Please leave empty or enter a valid URL.',
	}).or(z.literal(''))
,
  'state': z.string().optional(),
  'county': z.string().optional(),
  'country': z.string().nonempty({ message: 'Country is required' }),
  'city': z.string().nonempty({ message: 'City is required' }),
  'postcode': z.string().nonempty({ message: 'Postcode is required' }),
});

const DetailsFormNoPublishSchema = z.object({
  'full-name': z.string().nonempty({ message: 'Full name is required' }),
  'company-name': z.string().nonempty({ message: 'Company name is required' }),
  'email': z.string().email({ message: 'Invalid email' }).nonempty({ message: 'Email is required' })
});

export { DetailsFormSchema, DetailsFormNoPublishSchema }