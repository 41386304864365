import React, { useContext, useEffect } from 'react';

/* stylesheets */
import './App.css';
import './hitmix.css';

/* contexts */
import GlobalStateContext from './contexts/GlobalStateContext';
import GlobalStateProvider from './contexts/GlobalStateProvider';

/* pages */
import PurchasePage from './pages/index.js';
import FixHostRegistryPage from './pages/fix-host-registry.js';

/* routing */
import {
  Outlet,
  RouterProvider,
  Link,
  createRouter,
  createRoute,
  createRootRoute,
} from '@tanstack/react-router'

import { TanStackRouterDevtools } from '@tanstack/router-devtools'

const rootRoute = createRootRoute({
  notFoundComponent: PurchasePage
});

const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/',
  component: PurchasePage
});

const pricingPurchaseRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/pricing-purchase',
  component: PurchasePage
});

const pricingRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/pricing',
  component: PurchasePage
});

const purchaseRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/purchase',
  component: PurchasePage
});

const fixHostRegistryRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/update-host-directory',
  component: FixHostRegistryPage
});

const routeTree = rootRoute.addChildren([indexRoute, pricingPurchaseRoute, pricingRoute, purchaseRoute, fixHostRegistryRoute]);

const router = createRouter({ routeTree, notFoundMode: "root" });

function App() {
  useEffect(() => {
    document.title = "Hitmix Bingo | Purchase";
  }, []);
  
  return (
    <GlobalStateProvider>
      <RouterProvider router={router} />
    </GlobalStateProvider>
  );
}

export default App;
