import React, { useContext } from 'react';
import Select from 'react-select';
import ReactCountryFlag from "react-country-flag"
import GlobalStateContext from '../contexts/GlobalStateContext';

import styled from '@emotion/styled';

const Container = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	& div { font-size: 14px; }
`;

const CurrencyChooser = ({
	defaultValue,
	options,
	onChange
}) => {

	const { setOption } = useContext(GlobalStateContext);
	
	let labels = {
		'GBP': (
			<div style={{display: "flex", alignItems: "center", gap: 8}}>
				<ReactCountryFlag
					countryCode="GB"
					svg
				/>
				<div>British Pound</div>
			</div>
		),
		'AUD': (
			<div style={{display: "flex", alignItems: "center", gap: 8}}>
				<ReactCountryFlag
					countryCode="AU"
					svg
				/>
				<div>Australian Dollar</div>
			</div>
		),
		'USD': (
			<div style={{display: "flex", alignItems: "center", gap: 8}}>
				<ReactCountryFlag
					countryCode="US"
					svg
				/>
				<div>US Dollar</div>
			</div>
		),
		'EUR': (
			<div style={{display: "flex", alignItems: "center", gap: 8}}>
				<ReactCountryFlag
					countryCode="EU"
					svg
				/>
				<div>Euro</div>
			</div>
		),
	}
	
	let selectOptions = options.map(option => {
		let label = option;
		if(Object.keys(labels).includes(option)){
			label = labels[option];
		}
		return (
			{ value: option, label: label }
		)
	})
	
	let [ selectDefaultValue ] = selectOptions.filter(o => {
		return o.value == defaultValue;
	})
	
	return (
		<Container>
			<strong>Using currency: </strong>
			<Select
				defaultValue={selectDefaultValue}
				options={selectOptions}
				onChange={(d) => {
					/* set currency value */
					setOption('currency', d.value)
					onChange(d.value)
				}}
			/>
		</Container>
	)
}

export default CurrencyChooser;