import React, { useContext, useEffect, useState } from 'react';
import Step from '../components/Step';
import DetailsForm from '../components/DetailsForm';
import GlobalStateContext from '../contexts/GlobalStateContext';

export default () => {
    const [ showStep, setShowStep ] = useState(1);
    const [ isSaving, setIsSaving ] = useState(false);
    const [ productKey, setProductKey ] = useState('');
    const [ isChecking, setIsChecking ] = useState(false);
	const ctx = useContext(GlobalStateContext);

    const storeInRegistry = async () => {
        setIsSaving(true);

        // get data from form
        const {
            'full-name': full_name,  
            'company-name': company_name,
            email,
            'contact-telephone': contact_telephone,
            'website-url': website_url,
            state,
            county,
            country,
            city,
            postcode: postal_code
        } = ctx.options['form-data'];

        const params = {
            full_name, company_name, email, contact_telephone, website_url, state, county, country, city, postal_code, pkey: productKey
        }

        let query = Object.keys(params)
            .filter(k => !!params[k])
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');

        const response = await fetch(`https://purchase.hitmixbingo.com/api/host-directory/upsert?${query}`);
        const data = await response.json();
        if(data.success){
            alert("Your host directory entry has been updated! It should be live now – go check it out!")
            window.location.reload()
        } else {
            alert("Your host direcotry entry was not properly updated. Please report this error to Nick: \n\n" + JSON.stringify(data.errorReason));
        }
        
    }

    const stepOneOnClickNext = async () => {
        setIsChecking(true);
        const isKeyValid = await checkPkey(productKey);
        if(isKeyValid){
            setShowStep(2);
            setIsChecking(false);
        } else {
            // Display error message
            alert('The product key you entered was invalid. Please try again.');
            setIsChecking(false);
        }
    }

    const checkPkey = async (pkey) => {
        /* Check if the product key is valid. */
        const params = { pkey };
        let query = Object.keys(params)
             .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');
        
        const response = await fetch(`https://purchase.hitmixbingo.com/api/is-key-valid?${query}`);
        const data = await response.json();
        if(data.status == "valid"){
            return true;
        } else {
            return false;
        }
    }

    useEffect(() => {
		if(showStep != 1){
			window.scroll({top: 99999, behavior: 'smooth'});
		}
	}, [ showStep ])

    if(isSaving){
        return (
            <div class="loading-container">
                <div class="loading"></div>
                <h2>&nbsp;&nbsp;Saving...</h2>
            </div>
        )
    }

    return (
        <main>
            <div class="main__form">
                <h1>Update my entry in the host directory</h1>
                <p>If you hold a valid Hitmix Bingo license, you can use this self-service tool to <strong>add yourself to the host directory</strong>, or <strong>modify your existing entry on the registry.</strong></p>

                <Step showStep={showStep} setShowStep={setShowStep}
                    number={1}
                    title={"Please enter your Hitmix Bingo product key."}
                    description={"Please enter it precisely – including all hyphens and case sensitivity."}
                    stepContent={
                        <>
                        <div class="form__row">
                            <label for="product-key">Product Key</label>
                            <input name="product-key" type="text" onChange={e => setProductKey(e.target.value)} value={productKey} />
                        </div>

                        { !isChecking && <button type="submit" onClick={stepOneOnClickNext}>Next</button> }
                        { isChecking && <button type="submit" disabled>Checking key...</button>}
                        </>

                    }
                />

                <Step showStep={showStep} setShowStep={setShowStep}
                    number={2}
                    title={"Please enter the details you'd like published on the host directory."}
                    stepContent={
                        <DetailsForm
                            showHostDirectoryFields={true}
                            setShowStep={(n) => {
                                setShowStep(3); // XXX HACK
                            }}
                            emailCheckEnabled={false}
                        />
                    }
                />

                <Step showStep={showStep} setShowStep={setShowStep}
                    number={3}
                    title={"Please confirm the information you've entered above."}
                    description={"Once you're satisfied, click Save to store your information in the registry."}
                    stepContent={
                        <div>
                            <button onClick={storeInRegistry}>Save</button>
                        </div>
                    }
                />
            </div>
        </main>
    )
}