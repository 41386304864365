import React, { useContext, useEffect } from 'react';

/* contexts */
import GlobalStateContext from '../contexts/GlobalStateContext';

/* components */
import PurchaseForm from '../components/PurchaseForm';

export default () => {
	const { loading } = useContext(GlobalStateContext);  
	if(loading){
		return (
  	  		<div class="loading-container">
				<div class="loading"></div>
	 		</div>
		)
 	}
  
  	return (
	  <main>
	  	<PurchaseForm />  
	  </main>
  	)
}