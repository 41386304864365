import { useContext, useState } from "react"
import GlobalStateContext from "../contexts/GlobalStateContext"

function getCurrencySymbol(currencyCode){
	let dict = {
		'GBP': '£', 'EUR': '€', 'USD': '$', 'AUD': 'AU$ '
	}
	
	if(Object.keys(dict).includes(currencyCode)){
		return dict[currencyCode];
	}
	
	return currencyCode+ ' ';
}

const ConfirmOrderStep = () => {
	const ctx = useContext(GlobalStateContext);
	const currencySymbol = getCurrencySymbol(ctx.options.currency);
	const [ isProcessingOrder, setIsProcessingOrder ] = useState();
	
	async function processOrder() {
		setIsProcessingOrder(true);
		const post_body = {
			...ctx.options['form-data'],
			productId: ctx.options['selected-product'].id,
			publish: ctx.options['publish']
		};
		
		try {
			let response = await fetch('https://purchase.hitmixbingo.com/create_order.php', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(post_body)
			});
			let json = await response.json();	
			if(!json.success) throw new Error(1);
			const checkout_url = json.data.checkout_url;
			window.location.href = checkout_url;
		} catch (err) {
			alert("Something went wrong – please refresh the page and try again.")
			setIsProcessingOrder(false);
		}
		
	}
	
	const verb = typeof(ctx?.options?.['selected-product']?.recurring_price) !== "undefined" ? "Start Subscription for" : "Pay";
	const frequency = ctx?.options?.['selected-product']?.frequency || '';
	
	return (
		<div>
			<ul style={{fontSize: '1.2rem'}}>
				<li style={{marginBottom: '8px'}}>You're about to purchase <strong>Hitmix Bingo {ctx?.options?.['selected-product']?.shortName}</strong>.</li>
				<li>Your license key will be emailed to <strong>{ctx?.options?.['form-data']?.email}</strong>. Make sure that's correct!</li>
			</ul>
			
			{isProcessingOrder 
				?
				<button style={{width: '100%'}}disabled>Processing...</button>
				:
				<button onClick={processOrder} style={{width: '100%'}}>{verb} {currencySymbol}{ctx?.options?.['selected-product']?.price} {frequency}</button>
			}
		</div>
	)
}

export default ConfirmOrderStep