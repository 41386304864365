import styled from '@emotion/styled';

const WarningContainer = styled.div`
	background-color: #fffce9;
	border-left: 4px solid #ffaf00;
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 8px;
	max-width: 550px;
	margin: 8px 0;
`;

const WarningHeader = styled.h3`
	color: #3f2d06;
	margin: 0;
`;

const WarningBody = styled.p`
	color: #322205;
	margin: 0;
`;

export default ({ numLicenses = 1 }) => {
	let string = '';
	if(numLicenses == 1) {
		string = 'a license'
	} else {
		string = numLicenses + ' licenses'
	}
	
	return (
		<WarningContainer>
			<WarningHeader>You've already bought {string} with this email address</WarningHeader>
			<WarningBody>Make sure to <strong>properly cancel any existing licenses and subscriptions</strong> if you don't want to keep paying for them.</WarningBody>
		</WarningContainer>
	)
}