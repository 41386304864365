import styled from '@emotion/styled';
import { useState, useMemo, useContext, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { zodResolver } from '@hookform/resolvers/zod';
import { DetailsFormSchema, DetailsFormNoPublishSchema } from '../schemas/DetailsFormSchema';
import GlobalStateContext from '../contexts/GlobalStateContext';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { UsaStates } from 'usa-states';
import counties from 'uk-counties';

import AlreadyPurchasedWarning from './AlreadyPurchasedWarning';

const CountrySelector = ({ propsFromController }) => {
    const options = useMemo(() => countryList().getData(), [])
	
	  return <Select
	  	  inputRef={propsFromController.ref}
	  	  styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					maxWidth: '415px',
					border: '2px solid black',
					borderRadius: '0',
					padding: '2px 6px',
					fontSize: '1.25rem',
					color: 'black',
					margin: '8px 0'
				}),
				indicatorSeparator: () => ({ display: 'none' }),
			}}
		  options={options}
		  value={propsFromController.val}
		  onChange={val => propsFromController.onChange(val.value)} 
		/>
}



const UKCountySelector = ({ propsFromController }) => {
	const options = useMemo(() => {
		return counties.map(county => {
			return {
				value: county,
				label: county,
			}
		})
	}, []);
	
	return <Select
		inputRef={propsFromController.ref}
		styles={{
			control: (baseStyles, state) => ({
				...baseStyles,
				maxWidth: '415px',
				border: '2px solid black',
				borderRadius: '0',
				padding: '2px 6px',
				fontSize: '1.25rem',
				color: 'black',
				margin: '8px 0'
			}),
			indicatorSeparator: () => ({ display: 'none' }),
		}}
		options={options}
		value={propsFromController.val}
		onChange={val => propsFromController.onChange(val.value)} 
	/>
}


const StateSelector = ({ propsFromController }) => {
	const options = useMemo(() => {
		const { states } = new UsaStates();
		return states.map(state => {
			return {
				value: state.abbreviation,
				label: state.name
			}
		})
	}, []);
	
	return <Select
		inputRef={propsFromController.ref}
		styles={{
			control: (baseStyles, state) => ({
				...baseStyles,
				maxWidth: '415px',
				border: '2px solid black',
				borderRadius: '0',
				padding: '2px 6px',
				fontSize: '1.25rem',
				color: 'black',
				margin: '8px 0'
			}),
			indicatorSeparator: () => ({ display: 'none' }),
		}}
		options={options}
		value={propsFromController.val}
		onChange={val => propsFromController.onChange(val.value)} 
	/>
}

const ErrorHeader = styled.h3`
	margin: 0;
	color: #f94c75;
`;
const Errors = styled.ul`
	padding: 0 32px;
	color: #f94c75;
`;
const Error = styled.li`
	font-size: 1.1rem;
	margin-top: 4px;
	color: #732738;
`;

const DetailsFormErrors = ({ errors }) => {
	const errorsArray = Object.keys(errors).map(k => ({...errors[k], k}));
	if(errorsArray.length == 0) return null;

	return (
		<div>
			<ErrorHeader>Please correct the following errors:</ErrorHeader>
			<Errors>
				{errorsArray.map(error => {
					return (
						<Error key={error.k}>{error.message}</Error>
					)
				})}
			</Errors>
		</div>
	)
}

const DetailsForm = ({ showHostDirectoryFields = false, setShowStep, emailCheckEnabled = true }) => {
	const { watch, register, control, handleSubmit, formState: { errors, ...formState } } = useForm({
		resolver: zodResolver( showHostDirectoryFields ? DetailsFormSchema : DetailsFormNoPublishSchema )
	}); 
	
	const { setOption, ...ctx } = useContext(GlobalStateContext);
	let country_code = ctx?.data?.determine_currency?.data?.country_code || 'US';
	    country_code = country_code.toLowerCase();
	
	const currentlySelectedCountry = watch('country');
	const emailAddress = watch('email');
	
	const [ numLicenses, setNumLicenses ] = useState(0);
	useEffect(() => {
		(async() => {
			if(!emailCheckEnabled) return false;
			setNumLicenses(0);
			/* when the user enters their email address, check it
			with our backend to see if it is already in use with another
			pkey. if it is, we show up a warning */
			
			// check the email address is valid
			const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
			
			if(!emailPattern.test(emailAddress)) return;
			
			// check in with the backend
			try {
				const url = new URL('https://purchase.hitmixbingo.com/api/purchase/email-in-use');
				url.searchParams.append('email', emailAddress)
				const response = await fetch(url);
				const data = await response.json();	
				
				/* unpack data */
				if(!data?.success) throw new Error('non-success response');
				if(data?.count === undefined) throw new Error("no count in response");
				
				/* set num licenses to count */
				setNumLicenses(data.count);
			} catch (err){
				/* we couldn't reach the backend. fortunately this isn't SO serious, let's just ignore the error */
				console.warn("Error while trying to get email-in-use status, ignoring", err);
			}
			
			
			
		})()
		
	}, [ emailAddress]);
	
	const onSubmit = (d) => {
		setOption('form-data', d)
		console.log({d})
		setShowStep(4);
	}
	
	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<DetailsFormErrors errors={errors} />
			<div>
				<div class="form__row">
					<label for="full-name">Your Full Name*</label>
					<input name="full-name" type="text" {...register('full-name')} />
				</div>
				<div class="form__row">
					<label for="company-name">Company Name*</label>
					<input name="company-name" type="text" {...register('company-name')} />
				</div>
				<div class="form__row">
					<label for="email">Email Address*</label>
					<input name="email" type="email" {...register('email')}/>
				</div>
					
				{ numLicenses != 0 && <AlreadyPurchasedWarning numLicenses={2} /> }
				
				{showHostDirectoryFields && <hr class="form__separator" />}
				{showHostDirectoryFields && <div class="form__row">
					<label for="contact-telephone">Contact Telephone*</label>
					
					<Controller
						control={control}
						name={'contact-telephone'}
						render={
							({field: {onChange, value, ref}}) => (
								<PhoneInput
									inputProps={{
										type: 'tel',
									}}
									placeholder={''}
									country={country_code}
									onChange={phoneNumber => onChange(phoneNumber)}
								/>		
							)
						}
					/>
					
					
					{/*<input name="contact-telephone" type="tel" {...register('contact-telephone')} />*/}
				</div>}
				{showHostDirectoryFields &&<div class="form__row">
					<label for="website-url">Website URL</label>
					<input name="website-url" type="text" {...register('website-url')} />
				</div>}
				{showHostDirectoryFields && <hr class="form__separator" />}
				{showHostDirectoryFields && <div class="form__row">
					<label for="country">Country*</label>
					<Controller
						control={control}
						name="country"
						render={
							({field: {onChange, value, ref}}) => (
								<CountrySelector
									propsFromController={{onChange, value, ref}}	
								/>
							)
						}
					/>
				</div>}

				{showHostDirectoryFields && currentlySelectedCountry == "US" && <div class="form__row">
					<label for="country">State*</label>
					<Controller
						control={control}
						name="state"
						render={
							({field: {onChange, value, ref}}) => (
								<StateSelector
									propsFromController={{onChange, value, ref}}
								/>		
							)
						}
					/>
				</div>}

				{showHostDirectoryFields && currentlySelectedCountry == "GB" && <div class="form__row">
					<label for="county">County*</label>
					<Controller
						control={control}
						name="county"
						render={
							({field: {onChange, value, ref}}) => (
								<UKCountySelector
									propsFromController={{onChange, value, ref}}
								/>		
							)
						}
					/>
				</div>}

				{showHostDirectoryFields && <div class="form__row">
					<label for="city">City*</label>
					<input name="city" type="text" {...register('city')} />
				</div>}
				{showHostDirectoryFields && <div class="form__row">
					<label for="postcode">Postal / ZIP Code*</label>
					<input name="postcode" type="text" {...register('postcode')} />
				</div>}
			</div>
			<button type="submit">Next</button>
		</form>
	)
};

export default DetailsForm;