import React from 'react';
import styled from '@emotion/styled';

const OptionContainer = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 16px;
`;

const Radio = styled.input`
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
`;

const Label = styled.label`
  font-weight: 600;
  font-size: 18px;
  margin-left: 8px;
  padding: 0;
`;

// Added props radioValue and onRadioChange to manage the checked attribute and handle change events
const HostDirectoryStep = ({ onChange, radioValue, onRadioChange }) => {
  return (
	<div>
	  <OptionContainer>
		<Radio 
		  onChange={onRadioChange} 
		  checked={radioValue === 'yes'}
		  id="yes" 
		  name="publish" 
		  value="yes" 
		  type="radio" 
		/>
		<Label htmlFor="yes">Yes</Label>
	  </OptionContainer>
	  <OptionContainer>
		<Radio 
		  onChange={onRadioChange} 
		  checked={radioValue === 'no'}
		  id="no" 
		  name="publish" 
		  value="no" 
		  type="radio" 
		/>
		<Label htmlFor="no">No</Label>
	  </OptionContainer>
	</div>
  );
};

export default HostDirectoryStep;
