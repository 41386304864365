import { useContext } from 'react';
import GlobalStateContext from '../contexts/GlobalStateContext';
import styled from '@emotion/styled';

const Container = styled.ul`
	list-style-type: none;
	padding: 0;
`;

const Product = styled.li`
	display: flex;
	align-items: center;
	gap: 16px;
	width: 100%;
	margin: 16px 0;
	&:first-child { margin: 0 }

`;

const ProductTitle = styled.h3`
	font-size: 1.25rem;
	margin: 0;
`;
const ProductDescription = styled.p`
	font-size: 1.15rem;
	margin: 10px 0;
`;
const ProductPrice = styled.p`
	font-size: 1rem;
	margin: 8px 0;
	color: #565656;
`;

const Radio = styled.input`
	width: 24px;
	height: 24px;
`;


export default ({ onChange }) => {
	const { data, options, setOption } = useContext(GlobalStateContext);
	const { list_products } = data;
	
	let products;
	if(list_products?.data?.[options.currency]){
		products = list_products.data[options.currency];
	} else {
		setOption('currency', 'USD');
		products = list_products.data['USD']
	}
	
	function getCurrencySymbol(currencyCode){
		let dict = {
			'GBP': '£', 'EUR': '€', 'USD': '$', 'AUD': 'AU$ '
		}
		
		if(Object.keys(dict).includes(currencyCode)){
			return dict[currencyCode];
		}
		
		return currencyCode+ ' ';
	}
	
	function handleChange(product){
		onChange(product)
	}
	
	function getProductPrice(product){
		const currencySymbol = getCurrencySymbol(product.currency_code);
		if(product.recurring_price){
			return `${currencySymbol}${product.recurring_price} ${product.frequency}`;
		} else {
			return `${currencySymbol}${product.price}`
		}
	}
		
	return (
		<Container>
			{products.map(product => {
				return (
					<Product key={product.id}>
						<div>
							<Radio id={product.id} value={product.id} type="radio" name="product" onChange={() => handleChange(product)} />
						</div>
						<label for={product.id}>
							<ProductTitle>{product.shortName}</ProductTitle>
							<ProductDescription>{product.description}</ProductDescription>
							<ProductPrice>{getProductPrice(product)}</ProductPrice>
						</label>
					</Product>
				)
			})}
		</Container>		
	)
	
}